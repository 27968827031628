import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { NewProductsETA } from "../modules/productRoadmap/enums/date-new-products.enum";
import { INewProduct } from "../modules/productRoadmap/interfaces/new-product.interface";
import { SortingModes } from "../modules/common/enums/sorting-mode.enum";
dayjs.extend(utc);
dayjs.extend(localizedFormat);

class DateUtils {
  private locale: string = "en";

  constructor() {
    this.importLocale(window.navigator.language) ||
      this.importLocale(window.navigator.language.split("-")[0]);
  }

  private importLocale(currentLocale: string): boolean {
    const formattedCurrentLocale = currentLocale.toLowerCase();
    try {
      require(`dayjs/locale/${formattedCurrentLocale}.js`);
      this.locale = formattedCurrentLocale;
      return true;
    } catch {
      return false;
    }
  }

  public getLocale() {
    return this.locale;
  }
  public toUTCDate(date?: Date): Date | undefined {
    if (!date) return undefined;
  
    // Extract the UTC components from the date object
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    
    // Use Date.UTC to create a new UTC date at midnight (00:00:00)
    return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
  }
  public formatDate(date: Date, isFancyFormat = false, withHours = false, utc = false) {
    let template = "L";
    let locale = this.locale;

    if (isFancyFormat) {
      template = "LL";
      locale = "en";
    }

    if (withHours) {
      template += " HH:mm";
    }
    if(utc)
      return dayjs(date).utc().locale(locale).format(template);
    else 
    return dayjs(date).locale(locale).format(template);
  }

  public addDaysToDate(date: Date, nbDays: number, substract = false) {
    if (substract) date.setDate(date.getDate() - nbDays);
    else date.setDate(date.getDate() + nbDays);
    return date;
  }

  public getProductETA(product: INewProduct) {
    if(product.pilot)
      return NewProductsETA.PILOT;
    if (product.quarter && product.year) {
      const quarterEnd = product.quarter * 3;
      const quarterStart = quarterEnd - 2;

      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();

      if (
        product.year === currentYear &&
        currentMonth >= quarterStart &&
        currentMonth <= quarterEnd
      )
        return NewProductsETA.IMMINENT;

      return NewProductsETA.COMING_SOON;
    }

    return NewProductsETA.PLANNED;
  }

  public isNewOrUpdated(publicationDate: Date): boolean {
    const nbOfMonths = 3;
    const thresholdDate = dayjs().subtract(nbOfMonths, "months");
    return dayjs(publicationDate).isAfter(thresholdDate);
  }

  public sortFunction = (
    a: any,
    b: any,
    sortKey: string,
    sortMode = SortingModes.ASCEND
  ) => {
    return sortMode === SortingModes.ASCEND
      ? a[sortKey].getTime() - b[sortKey].getTime()
      : b[sortKey].getTime() - a[sortKey].getTime();
  };
}

export default new DateUtils();
