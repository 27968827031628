import "./styles.scss";

import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import { connect, ConnectedProps } from "react-redux";

import { marked } from "marked";
import { ApiSummary } from "../../../models/api-summary.model";
import DateUtils from "../../../../../utils/date.utils";
import BracedTitle from "../../../../common/components/bracedTitle";
import ApiDescriptionAPI from "../../../apis/api-description.api";
import { ApiEndpoint } from "../../../models/api-endpoint.model";
import Loader from "../../../../common/components/loader";
import NotFound from "../../../../common/components/notFound";
import { Routes } from "../../../../common/enums/routes.enum";
import UserSubscribed from "../userSubscribed";
import ReleaseNote from "./releaseNote";
import { ScreenType } from "../../../../common/enums/screen-type.enum";
import CustomScrollBars from "../../../../common/components/customScrollBars";
import AvailableEvents from "./availableEventsBySection";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";
import { apiFamilies } from "../../../constants/api-family.constant";
import { newProductsFamily } from "../../../../productRoadmap/constants/new-products-eta.constant";
import { CmsType } from "../../../enums/cms-type.enum";
import CmsAPI from "../../../../productRoadmap/apis/apiCms.api";
import { ICms } from "../../../../productRoadmap/interfaces/cms.interface";
import { IObject } from "../../../interfaces/product-swagger.interface";
import LinkPart from "./linkPart";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, RouteComponentProps {
  apiId: string;
  onBackClick?(): void;
}

interface IStates {
  apiSummary: ApiSummary | undefined;
  apiEndpoints: ApiEndpoint[];
  isLoading: boolean;
  areAvailableEventsLoading: boolean;
  faqOpened: boolean;
  listCms: ICms[] | undefined;
}

class ApiDescription extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      apiSummary: undefined,
      apiEndpoints: [],
      isLoading: false,
      areAvailableEventsLoading: true,
      faqOpened: false,
      listCms: [],
    };
  }

  componentDidMount() {
    this.getDesc();
    CmsAPI.getAllCms().then((res) => {
      this.setState({ listCms: res });
    });
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IStates>
  ) {
    if (prevProps.apiId !== this.props.apiId) {
      this.getDesc();
    }
  }

  private getDesc = () => {
    this.setState({ isLoading: true }, async () => {
      let apiSummary;
      await ApiDescriptionAPI.getApiSummary(this.props.apiId)
        .then((res) => (apiSummary = res))
        .catch(() => this.setState({ isLoading: false }));

      let apiEndpoints;
      await ApiDescriptionAPI.getApiEndpoints(this.props.apiId)
        .then((res) => (apiEndpoints = res))
        .catch(() => this.setState({ isLoading: false }));

      this.setState({
        apiSummary,
        apiEndpoints: apiEndpoints || [],
        isLoading: false,
      });
    });
  };

  private onBackClick = () => {
    if (this.isSidePanel()) {
      this.props.onBackClick && this.props.onBackClick();
    } else {
      const apiFamily = apiFamilies.find(
        (apiFamily) =>
          apiFamily.productFamily === this.state.apiSummary?.productFamily
      );

      if (apiFamily)
        this.props.history.push(
          apiFamily
            ? `${Routes.PRODUCTS}/${apiFamily.id}`
            : `${Routes.PRODUCTS}/${apiFamilies[0].id}`
        );
    }
  };

  private isSidePanel = () => {
    return (
      window.location.pathname.includes(Routes.COMMERCIAL_OFFERS) ||
      window.location.pathname.includes(Routes.API_APPLICATIONS)
    );
  };

  private displaySwagger = () => {
    this.props.history.push(
      `${Routes.PRODUCTS}/api/${this.props.apiId}/swagger`
    );
  };

  private toggleAreAvailableEventsLoading = (
    areAvailableEventsLoading = false
  ) => {
    this.setState({ areAvailableEventsLoading });
  };

  private getAvailableEvents = () => {
    if (this.props.apiId === "operation.trackandtrace.v1") {
      return (
        <AvailableEvents
          isLoading={this.state.areAvailableEventsLoading}
          toggleIsLoading={this.toggleAreAvailableEventsLoading}
        />
      );
    }
  };

  private getContent = (): JSX.Element => {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const getApiSecurityType = (): JSX.Element => {
      switch (this.state.apiSummary?.apiSecurity) {
        case "mixed":
          return <FormattedMessage id="mixed" />;
        case "private":
          return <FormattedMessage id="private" />;
        default:
          return <FormattedMessage id="public" />;
      }
    };

    const securityDisplayer = (apiEndpoint: ApiEndpoint) => {
      return (
        <div>
          {apiEndpoint.security
            .map((secValue) => {
              if (secValue.toLowerCase().includes("oauth")) return "OAuth2";
              else if (secValue.toLowerCase().includes("apikey"))
                return "ApiKey";
              // If the value is neither 'oauth2' nor 'apikey', return it as is
              return secValue;
            })
            .join(", ")}
        </div>
      );
    };

    if (this.state.apiSummary) {
      return (
        <>
          <div className="header">
            <div>
              <div className="title">{this.state.apiSummary.title}</div>
              <div className="name">
                {this.state.apiSummary.name}
                <UserSubscribed
                  apiId={this.props.apiId}
                  isUserSubscribed={!!this.state.apiSummary.isUserSubscribed}
                />
              </div>
            </div>

            {this.props.screenType !== ScreenType.PHONE && (
              <div className="view-swagger" onClick={this.displaySwagger}>
                <FormattedMessage id="apiDesc.viewSwagger" />
              </div>
            )}
          </div>

          <div className="tags-container">
            <div className="tag">
              <div className="tag-title">
                <FormattedMessage id="version" />
              </div>
              <div className="tag-content">
                {this.state.apiSummary.virtualizedVersion}
              </div>
            </div>

            {this.state.apiSummary.apiSecurity !== undefined && (
              <div className="tag">
                <div className="tag-title">
                  <FormattedMessage id="apiDesc.apiSecurity" />
                </div>
                <div className="tag-content">{getApiSecurityType()}</div>
              </div>
            )}

            <div className="tag">
              <div className="tag-title">
                <FormattedMessage id="apiDesc.lastUpdate" />
              </div>
              <div className="tag-content">
                {DateUtils.formatDate(this.state.apiSummary.publicationDate)}
              </div>
            </div>
          </div>

          <div className="description">
            <BracedTitle text={<FormattedMessage id="apiDesc.description" />} />
            <div
              className="description-content"
              dangerouslySetInnerHTML={{
                __html: marked(this.state.apiSummary.description).toString(),
              }}
            />
          </div>

          {this.props.screenType !== ScreenType.PHONE && (
            <>
              {this.getAvailableEvents()}

              <div className="methods">
                <BracedTitle text={<FormattedMessage id="apiDesc.methods" />} />

                {this.state.apiEndpoints.length === 0 ? (
                  <div className="no-method-found">
                    <FormattedMessage id="apiDesc.noMethodFound" />
                  </div>
                ) : (
                  <TableContainer id="methods-table">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="method-cell">
                            <FormattedMessage id="apiDesc.method" />
                          </TableCell>
                          <TableCell className="name-cell">
                            <div>
                              <FormattedMessage id="apiDesc.name" />
                            </div>
                          </TableCell>
                          <TableCell className="summary-cell">
                            <div>
                              <FormattedMessage id="apiDesc.summary" />
                            </div>
                          </TableCell>
                          <TableCell className="security-cell">
                            <div>
                              <FormattedMessage id="apiDesc.security" />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.apiEndpoints.map((apiEndpoint) => {
                          return (
                            <TableRow
                              key={`api-endpoint-row-${apiEndpoint.endpointName}`}
                            >
                              <TableCell>{apiEndpoint.methodType}</TableCell>
                              <TableCell>
                                <div>{apiEndpoint.endpointName}</div>
                              </TableCell>
                              <TableCell>
                                <div>{apiEndpoint.endpointDescription}</div>
                              </TableCell>
                              <TableCell>
                                {securityDisplayer(apiEndpoint)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
              {this.state.apiSummary.release.length > 0 && (
                <div className="release">
                  <BracedTitle text={<FormattedMessage id="releaseNotes" />} />
                  <div className="release-content">
                    {this.state.apiSummary.release.map((release, index) => (
                      <ReleaseNote
                        key={`release-note-${index}`}
                        index={index}
                        release={release}
                      />
                    ))}
                  </div>
                </div>
              )}
              {this.displayLinks()}
            </>
          )}
        </>
      );
    }

    return <NotFound text={<FormattedMessage id="noApiFound" />} />;
  };

  private findRoadmapContent = () => {
    let listRoadmap: IObject[] = [];
    this.state.listCms
      ?.find((e) => e.pageTitle === CmsType.ROADMAP)
      ?.content?.forEach((family) =>
        family.products.forEach((elt) => {
          if (elt.id === (this.state.apiSummary?.name as string)) {
            listRoadmap.push({ title: elt.name, response: elt.description });
          }
        })
      );
    return listRoadmap;
  };

  private findFaqContent = () => {
    let listFaq: IObject[] = [];
    this.state.listCms
      ?.find((e) => e.pageTitle === CmsType.FAQ)
      ?.faqQuestions?.forEach((question) => {
        if (question.referenceId === (this.state.apiSummary?.name as string)) {
          listFaq.push({ title: question.question, response: question.answer });
        }
      });
    return listFaq;
  };

  private displayLinkPart = (listElement: IObject[], roadmap: boolean) => {
    return (
      <div className="link">
        {roadmap ? (
          <BracedTitle text={<FormattedMessage id="upcomingFeatures" />} />
        ) : (
          <BracedTitle text={<FormattedMessage id="faq.insights" />} />
        )}
        {listElement.map((elt, index) => (
          <LinkPart key={index} index={index} linkElement={elt} />
        ))}
      </div>
    );
  };

  private displayLinks = () => {
    const roadmapList = this.findRoadmapContent();
    const faqList = this.findFaqContent();
    return (
      <>
        {roadmapList.length >= 1 && this.displayLinkPart(roadmapList, true)}
        {faqList.length >= 1 && this.displayLinkPart(faqList, false)}
      </>
    );
  };

  render() {
    const content = (
      <div className={`App-content ${this.props.screenType}`}>
        {this.getContent()}
      </div>
    );

    const finalContent = this.isSidePanel() ? (
      <CustomScrollBars>{content}</CustomScrollBars>
    ) : (
      content
    );

    return (
      <div id="api-description">
        <div className="back-container">
          <div className="back App-content" onClick={this.onBackClick}>
            {this.isSidePanel() ? (
              <>
                <CloseIcon />
                &nbsp;
                <span>
                  <FormattedMessage id="close" />
                </span>
              </>
            ) : (
              <>
                <ChevronLeftIcon />
                &nbsp;
                <span>
                  <FormattedMessage id="back" />
                </span>
              </>
            )}
          </div>
        </div>

        {finalContent}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(ApiDescription));
