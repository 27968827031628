import React from "react";
import { FormattedMessage } from "react-intl";

import { IHeaderMenuTab } from "../interfaces/header.interface";
import { TabValues } from "../enums/header.enum";
import { apiFamilies } from "../../productCatalog/constants/api-family.constant";
import { Routes } from "../../common/enums/routes.enum";
import { eventFamilies } from "../../productCatalog/constants/event-family.constant";
import { IProductsFamily } from "../../productCatalog/interfaces/products-family.interface";

const setProductFamilyItems = (productFamily: IProductsFamily) => ({
  title: productFamily.name,
  route:
    productFamily.id === "subscription"
      ? `${Routes.PRODUCTS}/api/eventhub.subscription.v1`
      : `${Routes.PRODUCTS}/${productFamily.id}`,
  shouldBeConnected: false,
});

const headerMenu: IHeaderMenuTab[] = [
  {
    id: "productCatalog",
    shouldBeConnected: false,
    title: <FormattedMessage id="productCatalog" />,
    tabValue: TabValues.PRODUCTS,
    subMenuSections: [
      {
        title: <FormattedMessage id="apiApplications.apis" />,
        items: apiFamilies.map(setProductFamilyItems),
      },
      {
        title: <FormattedMessage id="events" />,
        items: eventFamilies.map(setProductFamilyItems),
      },
      {
        title: <FormattedMessage id="pipeline" />,
        route: Routes.ROADMAP,
      },
    ],
  },

  {
    id: "roadmap",
    shouldBeConnected: false,
    title: <FormattedMessage id="roadmap" />,
    tabValue: TabValues.ROADMAP,
    route: Routes.ROADMAP,
  },

  {
    id: "commercialOffers",
    shouldBeConnected: true,
    title: <FormattedMessage id="commercialOffers" />,
    tabValue: TabValues.COMMERCIAL_OFFERS,
    route: Routes.COMMERCIAL_OFFERS,
  },

  {
    id: "myOrganization",
    shouldBeConnected: true,
    title: <FormattedMessage id="myOrganization" />,
    tabValue: TabValues.MY_ORGANIZATION,
    subMenuSections: [
      {
        items: [
          {
            title: <FormattedMessage id="myOrganization.apiApplications" />,
            route: Routes.API_APPLICATIONS,
            shouldBeConnected: false,
          },
          {
            title: <FormattedMessage id="myOrganization.eventApplications" />,
            route: Routes.EVENT_APPLICATIONS,
            shouldBeConnected: false,
          },
        ],
      },
      {
        items: [
          {
            title: <FormattedMessage id="myOrganization.businessPartners" />,
            route: Routes.BUSINESS_PARTNERS,
            shouldBeConnected: false,
          },
          {
            title: <FormattedMessage id="myOrganization.clientSettings" />,
            route: Routes.CLIENT_SETTINGS,
            shouldBeConnected: false,
          },
        ],
      },
    ],
  },

  {
    id: "help",
    shouldBeConnected: false,
    title: <FormattedMessage id="help" />,
    tabValue: TabValues.HELP,
    subMenuSections: [
      {
        items: [
          {
            title: <FormattedMessage id="apiStarting.title" />,
            route: Routes.STARTING_GUIDE,
            shouldBeConnected: false,
          },
          {
            title: <FormattedMessage id="apiUsage.title" />,
            route: Routes.USAGE_GUIDE,
            shouldBeConnected: false,
          },

          {
            title: <FormattedMessage id="credentialsGuide.head.title" />,
            route: Routes.CREDENTIALS_GUIDE,
            shouldBeConnected: false,
          },
          {
            title: <FormattedMessage id="faq.insights" />,
            route: Routes.FAQ,
            shouldBeConnected: false,
          },
        ],
      },
      {
        items: [
          {
            title: <FormattedMessage id="apiStatus.title" />,
            route: Routes.API_STATUS,
            shouldBeConnected: true,
          },
        ],
      },
      {
        items: [
          {
            title: <FormattedMessage id="releaseNotes.title" />,
            route: Routes.RELEASE_NOTES,
            shouldBeConnected: false,
          },
        ],
      },
    ],
  },
];

export default headerMenu;
