export enum ProductFamily {
  ALLOCATION="ALLOCATION",
  SCHEDULES = "SCHEDULES",
  PRICING = "PRICING",
  TRANSPORT_EXECUTION = "TRANSPORT_EXECUTION",
  DOCUMENTS = "DOCUMENTS",
  BL_SUITE = "BL",
  VISIBILITY = "VISIBILITY",
  INVOICE = "INVOICE",
  REFERENTIAL = "REFERENTIAL",
  SHIPMENT = "SHIPMENT",
  EQUIPMENT = "EQUIPMENT",
  TRANSPORT = "TRANSPORT",
  SCHEDULE="SCHEDULE",
  DOCUMENT="DOCUMENT",
  SUBSCRIPTION="SUBSCRIPTION",
  SHIPMENT_RELEASE_EVENT = "SHIPMENT_RELEASE_EVENT",
  SHIPMENT_RELEASE_API = "SHIPMENT_RELEASE_API"
}

export enum ProductType {
  API,
  EVENT,
}
