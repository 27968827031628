import "./styles.scss";

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ClickAwayListener, Typography, Checkbox } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";

import DateUtils from "../../../../../../utils/date.utils";
import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";
import { LongArrowLeftIcon } from "../../../../../../assets/icons";

export enum PeriodFilterEnum {
  LAST_24_HOURS,
  LAST_7_DAYS,
  LAST_30_DAYS,
  LAST_CALENDAR_MONTH,
  CUSTOM,
}

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux {
  onPeriodSelected(periodStart: Date, periodEnd: Date,periodFilter:PeriodFilterEnum): void;
}

interface IStates {
  areOptionsVisible: boolean;
  selectedPeriod: PeriodFilterEnum;
  selectedCustomPeriod: Date[];
  isSelected: boolean;
}

class PeriodFilter extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      areOptionsVisible: false,
      selectedPeriod: PeriodFilterEnum.LAST_24_HOURS,
      selectedCustomPeriod: [],
      isSelected: true
    };
  }

  public static getDefaultPeriodStart(): Date {
    return DateUtils.addDaysToDate(new Date(), 1, true);
  }

  private onClickAway = () => {
    this.setState({ areOptionsVisible: false });
  };

  private selectPeriod(selectedPeriod: PeriodFilterEnum) {
    const todayDate = new Date();
    let startDate: Date;
    let endDate = new Date();

    switch (selectedPeriod) {
      case PeriodFilterEnum.LAST_7_DAYS:
        startDate = DateUtils.addDaysToDate(todayDate, 7, true);
        break;
      case PeriodFilterEnum.LAST_30_DAYS:
        startDate = DateUtils.addDaysToDate(todayDate, 30, true);
        break;
      case PeriodFilterEnum.LAST_CALENDAR_MONTH:
        startDate = new Date(
          todayDate.getFullYear(),
          todayDate.getMonth() - 1,
          1
        );
        endDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
        endDate.setHours(0, 0, 0);

        break;
      case PeriodFilterEnum.LAST_24_HOURS:
      default:
        startDate = DateUtils.addDaysToDate(todayDate, 1, true);
        break;
    }

    this.setState({ selectedPeriod, areOptionsVisible: false }, () =>
      this.selectFullDays(startDate, endDate)
    );
  }

  private getItem(
    text: JSX.Element,
    periodFilter: PeriodFilterEnum
  ): JSX.Element {
    let onClick = () => this.selectPeriod(periodFilter);
    let content = text;

    if (periodFilter === PeriodFilterEnum.CUSTOM) {
      onClick = this.onCustomClick;

      let isCalendarOpen = false;
      let dates;

      if (this.state.selectedCustomPeriod.length === 0) {
        isCalendarOpen = true;
      } else {
        dates = (
          <div className="item-dates">
            <span>
              {DateUtils.formatDate(this.state.selectedCustomPeriod[0],false,false,true)}
            </span>
            <span>-</span>
            <span>
              {DateUtils.formatDate(this.state.selectedCustomPeriod[1],false,false,true)}
            </span>
          </div>
        );
      }

      if (this.state.selectedCustomPeriod.length === 2) {
        content = (
          <>
            <div className="item-title">
              {text}
              <div onClick={(event: any) => event.stopPropagation()}>
                <DateTimeRangePicker
                  className={this.props.screenType}
                  value={
                    this.state.selectedCustomPeriod
                      ? [DateUtils.toUTCDate(this.state.selectedCustomPeriod[0]), DateUtils.toUTCDate(this.state.selectedCustomPeriod[1])]
                      : undefined
                  }
                  disableClock={true}
                  isCalendarOpen={isCalendarOpen}
                  calendarIcon={<CalendarTodayOutlinedIcon />}
                  locale={DateUtils.getLocale()}
                  onChange={this.onDatePickerChange}
                  prevLabel={<LongArrowLeftIcon />}
                  nextLabel={<LongArrowLeftIcon />}
                />
              </div>
            </div>
            {dates}
          </>
        );
      }
    }

    return (
      <div
        className={`item ${this.state.selectedPeriod === periodFilter ? "selected" : ""
          }`}
        onClick={onClick}
      >
        {content}
      </div>
    );
  }

  private onCustomClick = () => {
    let startDate = DateUtils.addDaysToDate(new Date(), 1, true);
    let endDate = DateUtils.addDaysToDate(new Date(), 1);

    if (this.state.selectedCustomPeriod.length > 0) {
      startDate = this.state.selectedCustomPeriod[0];
      endDate = this.state.selectedCustomPeriod[1];
    }

    if (this.state.selectedCustomPeriod.length > 0) {
      this.selectFullDays(startDate, endDate);
    }

    this.setState({
      selectedPeriod: PeriodFilterEnum.CUSTOM,
      selectedCustomPeriod: [startDate, endDate],
    });
  };

  private onDatePickerChange = (
    dates: [(Date | undefined)?, (Date | undefined)?] | null
  ) => {
    if (dates && !!dates[0] && !!dates[1]) {
      const startDate = dates[0];
      const endDate = dates[1];

      this.setState(
        {
          selectedCustomPeriod: [startDate, endDate],
          areOptionsVisible: false,
        },
        () => this.selectFullDays(startDate, endDate)
      );
    }
  };

  private selectFullDays = (startDate: Date, endDate: Date) => {
    startDate = this.state.isSelected ? new Date(startDate?.setUTCHours(new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds(), new Date().getMilliseconds())) : new Date(startDate?.setUTCHours(0, 0, 0, 0));
    endDate = this.state.isSelected ? new Date(endDate?.setUTCHours(new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds(), new Date().getMilliseconds())) : new Date(endDate?.setUTCHours(23, 59, 59, 999))
    this.props.onPeriodSelected(
      startDate,
      endDate,
      this.state.selectedPeriod
    );
  };

  render() {
    const last24h = (
      <FormattedMessage
        id="periodFilter.lastXHours"
        values={{ period: "24" }}
      />
    );
    const last7days = (
      <FormattedMessage id="periodFilter.lastXDays" values={{ period: "7" }} />
    );
    const last30days = (
      <FormattedMessage id="periodFilter.lastXDays" values={{ period: "30" }} />
    );
    const lastCalendarMonth = (
      <FormattedMessage id="periodFilter.lastCalendarMonth" />
    );
    const custom = <FormattedMessage id="periodFilter.customPeriod" />;

    let filterText = <FormattedMessage id="periodFilter" />;

    switch (this.state.selectedPeriod) {
      case PeriodFilterEnum.LAST_24_HOURS:
        filterText = last24h;
        break;
      case PeriodFilterEnum.LAST_7_DAYS:
        filterText = last7days;
        break;
      case PeriodFilterEnum.LAST_30_DAYS:
        filterText = last30days;
        break;
      case PeriodFilterEnum.LAST_CALENDAR_MONTH:
        filterText = lastCalendarMonth;
        break;
      case PeriodFilterEnum.CUSTOM:
        filterText = custom;
        break;
      default:
        break;
    }

    return (
      <ClickAwayListener onClickAway={this.onClickAway}>
        <div
          className="period-filter-container"
          onClick={(event) => event.stopPropagation()}
        >
          <div
            className="custom-button period-filter"
            onClick={() =>
              this.setState({
                areOptionsVisible: !this.state.areOptionsVisible,
              })
            }
          >
            {filterText}
            <KeyboardArrowDownIcon />
          </div>

          {this.state.areOptionsVisible && (
            <div className="items-container">
              {this.getItem(last24h, PeriodFilterEnum.LAST_24_HOURS)}
              {this.getItem(last7days, PeriodFilterEnum.LAST_7_DAYS)}
              {this.getItem(last30days, PeriodFilterEnum.LAST_30_DAYS)}
              {this.getItem(
                lastCalendarMonth,
                PeriodFilterEnum.LAST_CALENDAR_MONTH
              )}
              {this.getItem(custom, PeriodFilterEnum.CUSTOM)}

              {this.state.selectedPeriod === PeriodFilterEnum.CUSTOM && <div className="item-check">
                <Typography>Current date time</Typography>
                <Checkbox onChange={() => this.setState((prevState) => ({
                  isSelected: !prevState.isSelected
                }))}
                  checked={this.state.isSelected}
                  style={{ marginTop: "-10px" }} />
              </div>}
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState, {});
export default connector(PeriodFilter);
